<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
import ChangeDate from "@/components/changeDateComponent";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
// import { tableData } from "./dataAdvancedtable";
import btnComponent from "@/components/btnComponent";
// import branchComponent from "@/components/branchComponent.vue";
import DatePicker from "vue2-datepicker";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "จ่ายอะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    ChangeDate,
    Multiselect,
    DatePicker,
    // Multiselect,
  },
  // Multiselect, DatePicker,
  data() {
    return {
      // tableData: tableData,
      isLoading: false,
      loading: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      title: "จ่ายอะไหล่",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: "จ่ายอะไหล่",
          active: false,
        },
      ],
      userBranch: "",
      service: [],
      svId: "",
      // vin: "",
      selected: [],
      optionBranch: [],
      submitform: false,
      totalRows: 1,
      currentPage: 1,
      currentPageAppointment: 1,
      perPage: 10,
      perPageAppointment: 10,
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      filter: {
        pickingCode: "",
        licensePlate: "",
        customerNameTh: "",
        customerFamilyNameTh: "",
        pickingDate: "",
        userBranchId: "",
      },
      rows: [],
      sortBy: "age",
      sortDesc: false,
      pkId: "",
      urlPrint: "",
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "pickingCode",
          sortable: true,
          label: "รหัสใบจ่ายอะไหล่",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุล",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "pickingDate",
          sortable: true,
          label: "วันที่เข้ารับบริการ",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดเงิน",
          class: "text-end",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
          class: "text-center",
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
      overlayFlag: false,
    };
  },
  validations: {
    vin: {
      required,
    },
    svDate: {
      required,
    },
    distance: {
      required,
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rowsTest.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = user.userAccessBranch;
  },
  created() {
    this.getData();
  },
  methods: {
    getData: function() {
        this.overlayFlag = true;
        this.isLoading = true;
        useNetw
          .get("api/part-picking", {
            params: {
              page: this.currentPage,
              perPage: this.perPage,
              pickingCode: this.filter.pickingCode,
              licensePlate: this.filter.licensePlate,
              customerNameTh: this.filter.customerNameTh,
              customerFamilyNameTh: this.filter.customerFamilyNameTh,
              pickingDate: this.filter.pickingDate,
              // branchName: this.filter.branchName,
              "branchId[]": this.filter.userBranchId.branchId,
            },
          })
          .then((response) => {
            this.rows = response.data.data;
            this.rows.to = response.data.to;
            this.rows.from = response.data.from;
            this.rows.total = response.data.total;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
            this.isLoading = false;
          });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows;
    },
    deletePkPart(pickingId) {
      this.pkId = pickingId;
      this.overlayFlag = true;
      useNetw
        .delete("api/part-picking/delete", {
          data: {
            pickingId: this.pkId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alertDelete(pickingId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ !!?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deletePkPart(pickingId);
          }
        });
    },
    getPickingPrint(pickingId) {
      this.pkId = pickingId;
      this.loading = true;
      useNetw
        .get("api/part-picking/print", {
          params: {
            pickingId: this.pkId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          window.location = (this.urlPrint);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleSearch() {
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <b-skeleton-wrapper :loading="loading">
            <!-- skeleton  -->
            <template #loading>
              <b-skeleton-table></b-skeleton-table>
            </template>
            <div class="card">
              <div class="card-body">
                <!-- <h4 class="card-title">Service List</h4> -->

                <!-- Search -->
                <div class="row justify-content-md-center">
                  <div class="col-sm-12 col-md-12">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <div class="row">
                          <div class="col-6 col-sm-6 col-md-4">
                            <div class="mb-3 position-relative">
                              <label>สาขา:</label>
                              <multiselect
                                v-model="filter.userBranchId"
                                label="nameTh"
                                type="search"
                                :options="optionBranch"
                                :show-labels="false"
                                open-direction="bottom"
                                placeholder=""
                              >
                                <span slot="noResult">ไม่พบข้อมูล</span>
                              </multiselect>
                            </div>
                          </div>
                          <div class="col col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              <label>รหัสใบจ่ายอะไหล่:</label>
                              <input
                                v-model="filter.pickingCode"
                                type="search"
                                class="form-control"
                                @keyup.enter="handleSearch"
                              />
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-2">
                            <div class="mb-3 position-relative">
                              <label>ชื่อลูกค้า:</label>
                              <input
                                v-model="filter.customerNameTh"
                                type="search"
                                class="form-control"
                                @keyup.enter="handleSearch"
                              />
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-2">
                            <div class="mb-3 position-relative">
                              <label>นามสกุล:</label>
                              <input
                                v-model="filter.customerFamilyNameTh"
                                type="search"
                                class="form-control"
                                @keyup.enter="handleSearch"
                              />
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              <label>ป้ายทะเบียน:</label>
                              <input
                                v-model="filter.licensePlate"
                                type="search"
                                class="form-control"
                                @keyup.enter="handleSearch"
                              />
                            </div>
                          </div>
                          <div class="col-6 col-sm-6 col-md-2">
                            <div class="mb-3 position-relative">
                              <label> วันที่จ่ายอะไหล่:</label>
                              <date-picker
                                v-model="filter.pickingDate"
                                :first-day-of-week="1"
                                format="YYYY-MM-DD"
                                @input="handleSearch"
                                value-type="format"
                                lang="en"
                              ></date-picker>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-6 col-md-12 text-end">
                            <div class="mb-3 position-relative">
                              &nbsp;
                              <b-button
                                v-b-modal.modalService
                                class="btn m-1"
                                variant="primary"
                                :to="{ name: 'detail-part-picking' }"
                              >
                                <i class="uil-plus"></i>
                                สร้าง
                              </b-button>
                              <btnComponent
                                class="mt-1 m-1"
                                @click="handleSearch"
                                changeStyle="search"
                              >
                              </btnComponent>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-12 col-sm-3 col-md-3">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            แสดงผล&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                              @input="handlePageChange"
                            ></b-form-select
                            >&nbsp;รายการ
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->

                <tableData :fields="fields" :items="rows">
                  <template #cell(pickingDate)="rows">
                    <changeDate :date="rows.item.pickingDate"></changeDate>
                  </template>
                  <template #cell(index)="rowsTest">
                    {{ rowsTest.index + 1 }}
                  </template>
                  <template #cell(status)="rowReq">
                    <span
                      class="badge bg-warning font-size-12"
                      v-if="rowReq.item.status === 'DFT'"
                    >
                      ร่าง
                    </span>
                    <span
                      class="badge bg-info font-size-12"
                      v-if="rowReq.item.status === 'SUBMIT'"
                    >
                      รออนุมัติ
                    </span>
                    <span
                      class="badge bg-success font-size-12"
                      v-if="rowReq.item.status === 'APV'"
                    >
                      ยืนยันการจ่ายแล้ว
                    </span>
                    <span
                      class="badge bg-danger font-size-12"
                      v-if="rowReq.item.status === 'CAN'"
                    >
                      ยกเลิกแล้ว
                    </span>
                  </template>
                  <template #cell(action)="rows">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <router-link
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          :to="{
                            name: 'detail-part-pickingId',
                            params: { pickingId: rows.item.pickingId },
                          }"
                          title="View"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </router-link>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="alertDelete(rows.item.pickingId)"
                        >
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-success"
                          v-b-tooltip.hover
                          title="Print"
                          @click="getPickingPrint(rows.item.pickingId)"
                        >
                          <i class="uil-print font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>
                </tableData>

                <div class="row">
                  <div class="col">
                    <div class="align-items-center">
                      แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                      {{ this.rows.total }} รายการ
                    </div>
                  </div>
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows.total"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-skeleton-wrapper>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
